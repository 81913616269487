<template>
  <div class="gg-container" style="padding: 10px 20px">
    <el-tabs v-model="activeName" @tab-click="handleClickTabs">
      <el-tab-pane label="医生对比" name="first">
        <div class="search-container">
          <div class="search-container-fn-input">
            <label>月份：</label>
            <el-date-picker
              v-model="month"
              type="month"
              size="small"
              placeholder="月份"
              @change="getListSecond('restPage')"
            >
            </el-date-picker>
          </div>

          <el-button
            size="mini"
            type="primary"
            style="
              padding: 6px 7px 7px 8px;
              background-color: #2362fb;
              border-color: #2362fb;
              margin-left: auto;
            "
            @click="handleExportOrganizationContrast()"
          >
            <!-- <i class="iconfont icon-daoru" style="font-size: 13px" />
            <span style="margin-left: 5px; font-size: 12px">导出</span> -->
          </el-button>
        </div>

        <el-table
          border
          v-loading="listLoading"
          :header-cell-style="{ 'text-align': 'center' }"
          :data="tableDataSecond"
          style="width: 100%"
        >
          <el-table-column
            fixed
            label="医生姓名"
            prop="doctor.doctor_name"
            align="center"
            width="80"
          >
          </el-table-column>
          <el-table-column
            fixed
            prop="pic_diag_count"
            label="图文问诊数"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="pic_diag_amount"
            label="图文问诊金额"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="video_diag_count"
            label="视频问诊数"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="video_diag_amount"
            label="视频问诊金额"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="diag_amount"
            label="问诊金额小计"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="diag_count" label="问诊数" align="center">
          </el-table-column>
          <el-table-column prop="name" label="补贴金额" align="center">
          </el-table-column>
          <el-table-column prop="name" label="总计" align="center">
          </el-table-column>
          <el-table-column label="退诊数" align="center">
            <el-table-column
              prop="refund_diag_no_count"
              label="未接诊"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="refund_diag_yes_count"
              label="已接诊"
              align="center"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column label="退诊金额" align="center">
            <el-table-column
              prop="refund_diag_no_amount"
              label="未接诊"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="refund_diag_yes_amount"
              label="已接诊"
              align="center"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column prop="emr_count" label="电子病历数" align="center">
            <el-table-column
              prop="ask_emr_count"
              label="咨询类问诊"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="diag_emr_count"
              label="诊疗类问诊"
              align="center"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column prop="pres_count" label="电子处方数" align="center">
            <el-table-column
              prop="wm_pres_count"
              label="成药处方"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="ts_pres_count"
              label="中药处方"
              align="center"
            >
            </el-table-column>
          </el-table-column>
        </el-table>

        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="listQuery.page"
          :limit.sync="listQuery.limit"
          @pagination="getListSecond"
        />
      </el-tab-pane>

      <el-tab-pane label="医生详情" name="second">
        <div class="search-container">
          <div class="search-container-fn-input">
            <label>年份：</label>
            <el-date-picker
              v-model="searchParamsFirst.year"
              type="year"
              size="small"
              clearable
              placeholder="选择年"
              value-format="yyyy"
              @change="getList('restPage')"
            >
            </el-date-picker>
          </div>

          <div class="search-container-fn-input">
            <label>医生姓名：</label>
            <el-select
              v-model="searchParamsFirst.doctor_id"
              filterable
              clearable
              size="small"
              placeholder="请输入医生姓名"
              @change="getList('restPage')"
            >
              <el-option
                v-for="item in doctorList"
                :key="item.id"
                :label="item.doctor_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>

          <el-button
            size="mini"
            type="primary"
            style="
              padding: 6px 7px 7px 8px;
              background-color: #2362fb;
              border-color: #2362fb;
              margin-left: auto;
            "
            @click="handleExportOrganizationDetail()"
          >
            <!-- <i class="iconfont icon-daoru" style="font-size: 13px" />
            <span style="margin-left: 5px; font-size: 12px">导出</span> -->
          </el-button>
        </div>

        <el-table
          border
          v-loading="listLoading"
          :header-cell-style="{ 'text-align': 'center' }"
          :data="tableDataFirst"
          style="width: 100%"
        >
          <el-table-column
            fixed
            label="月份"
            prop="month"
            align="center"
            width="80"
          >
          </el-table-column>
          <el-table-column
            fixed
            prop="pic_diag_count"
            label="图文问诊数"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="pic_diag_amount"
            label="图文问诊金额"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="video_diag_count"
            label="视频问诊数"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="video_diag_amount"
            label="视频问诊金额"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="diag_amount"
            label="问诊金额小计"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="diag_count" label="问诊数" align="center">
          </el-table-column>
          <el-table-column prop="name" label="补贴金额" align="center">
          </el-table-column>
          <el-table-column prop="name" label="总计" align="center">
          </el-table-column>
          <el-table-column label="退诊数" align="center">
            <el-table-column
              prop="refund_diag_no_count"
              label="未接诊"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="refund_diag_yes_count"
              label="已接诊"
              align="center"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column label="退诊金额" align="center">
            <el-table-column
              prop="refund_diag_no_amount"
              label="未接诊"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="refund_diag_yes_amount"
              label="已接诊"
              align="center"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column prop="emr_count" label="电子病历数" align="center">
            <el-table-column
              prop="ask_emr_count"
              label="咨询类问诊"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="diag_emr_count"
              label="诊疗类问诊"
              align="center"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column prop="pres_count" label="电子处方数" align="center">
            <el-table-column
              prop="wm_pres_count"
              label="成药处方"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="ts_pres_count"
              label="中药处方"
              align="center"
            >
            </el-table-column>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import {
  getAdminReportDoctorCompare,
  getAdminReportDoctorDetail,
  getAdminCommonDocList,
} from "@/api/finance";
export default {
  name: "DoctorReport",
  components: {
    Pagination,
  },
  created() {
    this.getList();
    this.getListSecond();
    this._getAdminCommonDocList();
  },
  data() {
    return {
      activeName: "first",
      searchParamsFirst: {
        year: this.$moment().format("YYYY"),
        doctor_id: "",
      },
      month: this.$moment().format("YYYY-MM"),
      searchParamsSecond: {
        month: "",
      },
      organizationList: [],
      listLoading: false,
      tableDataFirst: [],
      tableDataSecond: [],
      listQuery: {
        page: 1,
        limit: 10,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      doctorName: "",
      doctorList: [],
      total: 0,
    };
  },
  methods: {
    test() {},
    handleClickTabs(tab, event) {
      console.log(tab, event);
    },
    getList(rest) {
      if (rest == "restPage") {
        this.listQuery.page = 1;
      }
      this.listLoading = true;

      let params = { ...this.searchParamsFirst };
      getAdminReportDoctorDetail(params)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            this.tableDataFirst = data.data;
          }
          this.listLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.listLoading = false;
        });
    },
    getListSecond(rest) {
      if (rest == "restPage") {
        this.listQuery.page = 1;
      }
      let date = new Date(this.month);
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      this.searchParamsSecond.month = y + "" + m;
      let params = { ...this.searchParamsSecond };
      params.page = this.listQuery.page;
      params.page_size = this.listQuery.limit;
      getAdminReportDoctorCompare(params)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            let _data = data.data;
            this.total = _data.total;
            this.listQuery.limit = Number(_data.per_page);
            this.tableDataSecond = _data.data;
          }
          this.listLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.listLoading = false;
        });
    },
    _getAdminCommonDocList() {
      getAdminCommonDocList().then((response) => {
        if (response.code == 200) {
          this.doctorList = response.data;
        }
      });
    },

    handleExportOrganizationDetail() {},
    handleExportOrganizationContrast() {},
  },
};
</script>

<style scoped>
</style>
